import React from 'react';
import SelectMessage from '../panels/SelectMessage';

interface Message {
  name: string,
  selects: Array<string>,
}

export default ((props) => {

  var messages: Array<Message> = [
    {name: 'こちらはフレームとレンズ代が含まれています。\nどんな度数でも薄型レンズ代込みです。\nオプションレンズは別途料金がかかります。', selects: []},
    {name: '視力測定だけでも無料で承ります。', selects: []},
    {name: 'レンズの在庫がある場合、メガネはお会計終了後最速20分で作成出来ます。\n店の混雑状況により、メガネの作成時間が変化します。', selects: []},
    {name: '2本同時購入の場合2本目は半額になります。\n※ご本人様限定です。\n※お値段の低いほうが対象です。\n※一部対象外品がございます。\n※割引の併用はできません。', selects: []},
    {name: '【アウトレット店舗専用】\n2本以上同時購入の場合2本目以降はどなたでも千円引きになります。\n※お値段の低いほうが対象です。\n※一部対象外品がございます。\n※割引の併用はできません。', selects: []},
    {name: 'オンデーズのサングラスはUVカット付きのレンズです。', selects: []},
    {name: 'パスポートをお持ちでしたら免税できます。', selects: []},
    {name: '今回のメガネの度数は決まってますか？', selects: ['視力測定', 'お持ちのメガネ', '度なし']},
    {name: '度付きサングラスはレンズ代が含まれてないので、追加料金8400円です。\nお渡しは1週間後にできます。', selects: []}
  ];

  return (
    <>
      <SelectMessage messages={messages}/>
    </>
  );
}) as React.FC;
